import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet';
import layoutStyles from '../styles/style.module.scss'

const ExperiencePage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>HP Gregorio</title>
            </Helmet>
            <Layout>
                <h1 className={layoutStyles.t1}>Experiência profissional</h1>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>HP Gregório Consultoria, Consultor ambiental autônomo
                            <p className={layoutStyles.parag}>02/2014 - atual</p>
                        </li>
                        <li className={layoutStyles.t2}>Mineral Engenharia e Meio Ambiente, São Paulo/SP, Brasil
                            <p className={layoutStyles.parag}>03/2011 - 01/2014</p>
                        </li>
                        <li className={layoutStyles.t2}>GeoHidroEco, Bogotá, Colômbia
                            <p className={layoutStyles.parag}>08/2010 - 12/2010</p>
                        </li>
                        <li className={layoutStyles.t2}>Hidromares Assessoria Ambiental e Oceanografia, Santos/SP, Brasil
                            <p className={layoutStyles.parag}>03/2007 - 02/2011</p>
                        </li>
                        <li className={layoutStyles.t2}>Applied Science Consultoria - ASA South America, São Paulo/SP, Brasil
                            <p className={layoutStyles.parag}>01/2007 - 03/2007</p>
                        </li>
                    </ul>
                </nav>
                <p><br></br></p>
                <h1 className={layoutStyles.t1}>Experiência em pesquisa</h1>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>Laboratório de Hidrodinâmica Costeira - Instituto Oceanográfico da Universidade de São Paulo
                            <p className={layoutStyles.parag}>03/2010 – 03/2014: Doutorando em Oceanografia Física e análise de dados do Projeto PEMCA (03/2010-03/2011 – Financiado por STATOIL)</p>
                        </li>
                        <li className={layoutStyles.t2}> Laboratório de Hidrodinâmica Costeira - Instituto Oceanográfico da Universidade de São Paulo
                            <p className={layoutStyles.parag}>01/2007 - 12/2009: Mestrando em Oceanografia Física e análise de dados do Projeto PROABROLHOS (Financiado pela PETROBRAS)</p>
                        </li>
                        <li className={layoutStyles.t2}>Programa Antártico Brasileiro - Modelagem da Qualidade da água da Baía do Almirantado - Antártica
                            <p className={layoutStyles.parag}>11/2004: Pesquisador com 456 h na Estação Antártica Brasileira - Ilha Rei George - Antártica (Financiado pelo CNPq).</p>
                        </li>
                        <li className={layoutStyles.t2}>Programa Antártico Brasileiro - Modelagem da Qualidade da água da Baía do Almirantado - Antártica
                            <p className={layoutStyles.parag}>11/2004: Pesquisador com 275 h no Navio de Apoio Oceanográfico Ary Rongel - Ilhas Sheetlands do Sul - Antártica (Financiado pelo CNPq)</p>
                        </li>
                        <li className={layoutStyles.t2}> Projeto Deproas - Instituto Oceanográfico da Universidade de São Paulo / Petrobras
                            <p className={layoutStyles.parag}>04/2004: Pesquisador com 204 h no Navio Oceanográfico W. Besnard (Universidade de São Paulo) - Bacias de Santos e Campos (Financiado pelo CNPq e PETROBRAS).</p>
                        </li>
                        <li className={layoutStyles.t2}>Laboratório de Hidrodinâmica Costeira - Instituto Oceanográfico da Universidade de São Paulo
                            <p className={layoutStyles.parag}>05/2003 - 12/2006: Iniciação Científica.</p>
                        </li>
                        <li className={layoutStyles.t2}>Laboratório de Produção Primária - Instituto Oceanográfico da Universidade de São Paulo
                            <p className={layoutStyles.parag}>01/2003 – 05/2003: Iniciação Científica.</p>
                        </li>
                    </ul>
                </nav>
            </Layout>
        </div>
    )    
}

export default ExperiencePage